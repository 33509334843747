<template>
  <div class="shadow-[0_0_4px_rgba(0,0,0,0.03)] rounded-[7px] overflow-hidden">
    <BaseButton :class="classes.button" @click="toggleFaqItem">
      <div
        :class="[classes.question, isLargeSize ? classes.questionLarge : '']"
      >
        <Icon
          v-if="question.hasOwnProperty('icon')"
          name="common:fire"
          class="me-1"
          size="13"
        />
        {{ question.title }}
      </div>
      <Icon
        name="common:chevron"
        class="!text-icon-disable rotate-90"
        :size="xlAndLarger && isLargeSize ? 16 : 12"
        :class="['duration-300', { '!-rotate-90': isExpandedFaqItem }]"
      />
    </BaseButton>
    <Collapse :when="isExpandedFaqItem">
      <dd :class="[classes.answer, isLargeSize ? classes.answerLarge : '']">
        <div v-html="question.answer"></div>
      </dd>
    </Collapse>
  </div>
</template>

<script setup lang="ts">
import { ref, type PropType } from 'vue'
import { cx } from 'cva'
import { Collapse } from 'vue-collapsed'

const props = defineProps({
  question: {
    type: Object as PropType<FaqQuestion>,
    default() {
      return {}
    },
    required: true,
  },
  isLargeSize: {
    type: Boolean,
    default: false,
    required: false,
  },
})

const classes = {
  button: cx([
    'flex',
    'items-center',
    'w-full',
    'justify-between',
    'px-[11px]',
    'py-[15px]',
    'xl:p-5',
    'bg-bg-main-primary',
  ]),
  question: cx([
    'flex',
    'items-center',
    'font-bold',
    'text-[14px]',
    'xl:text-base',
    'text-primary',
    'mr-[15px]',
  ]),
  questionLarge: cx(['!text-[16px]/[19px]', 'xl:!text-[24px]/[28px]']),
  answer: cx([
    'text-[14px]',
    'xl:text-base',
    'px-[11px]',
    'pb-[15px]',
    'xl:px-5',
    'xl:pb-5',
    'bg-bg-main-primary',
  ]),
  answerLarge: cx(['!text-[14px]/[21px]', 'xl:!text-[16px]/[24px]']),
}

const viewport = useViewport()
const xlAndLarger = computed(() => viewport.isGreaterOrEquals('xl'))

const isExpandedFaqItem = ref(false)

const toggleFaqItem = () => {
  props.question.reachGoal ? props.question.reachGoal() : undefined
  isExpandedFaqItem.value = !isExpandedFaqItem.value
}
</script>