<template>
  <div :class="[sectionStyles, 'container']">
    <h3 v-if="isShowTitle" :class="sectionTitleStyles">
      Частые вопросы
    </h3>
    <div
      class="grid grid-cols-1 xl:grid-cols-2 gap-2.5 xl:gap-x-5 xl:gap-y-2.5"
    >
      <div
        v-for="(column, columnIndex) in columns"
        :key="columnIndex"
        class="grid gap-2.5 content-start m-0"
      >
        <BaseFaqItem
          v-for="(item, index) in column"
          :key="index"
          :question="item"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue'
import { sectionStyles, sectionTitleStyles } from '@/utils'

const props = defineProps({
  questions: {
    type: Array as PropType<Array<FaqQuestion>>,
    default() {
      return [
        {
          title: 'Есть ли гарантия на устройства?',
          answer:
            'Да. Мы даём гарантию на срок от 12 месяцев при покупке. Также можем оформить дополнительную гарантию или страховку в любом сервисном центре Pedant.ru. При условии оформления подписки, гарантия дается на весь срок использования и входит в стоимость.',
        },
        {
          title:
            'Если я закажу устройство, где я смогу его забрать и как быстро?',
          answer:
            'Мы доставим устройство в выбранный вами филиал в течение 1-3 дней с момента оформления заказа. Смартфон дополнительно проверят при вас и выдадут гарантию при покупке.',
        },
        {
          title: 'Какие устройства продаются на сайте?',
          answer:
            'Все устройства оригинальные:'
            + '<br/> '
            + '- идеально новые;<br/> '
            + '- витринные устройства;<br/> '
            + '- обменные устройства;<br/> '
            + '- остатки со складов (когда модель ушла с продажи);<br/>'
            + '- устройства, купленные по Trade-in крупными компаниями.',
        },
        {
          title: 'Какие устройства выдаются по подписке?',
          answer:
            'Вы можете взять по подписке Б/У Apple iPhone в отличном и хорошем состоянии. Все устройства оригинальные и прошли проверку',
        },
        {
          title: 'Как вернуть товар?',
          answer:
            '<p class="mb-[14px]">Вернуть товар можно в течение 14 дней со дня покупки. Для этого необходим чек. Мы не сможем принять повреждённые или залитые жидкостью устройства.</p>'
            + '<p class="mb-[14px]">Возврат товара осуществляется в соответствии с Законом РФ от 07.02.1992 N 2300-1 «О защите прав потребителей» и Постановлением Правительства РФ от 27.09.2007 N 612 «Об утверждении Правил продажи товаров дистанционным способом».</p>'
            + '<p>Вернуть устройство по подписке можно после окончания срока подписки без дополнительных платежей (бесплатно). Также можно вернуть смартфон раньше окончания срока подписки с комиссией за досрочный возврат.</p>',
        },
      ]
    },
  },
  isShowTitle: {
    type: Boolean,
    default: true,
    required: false,
  },
})

const columns = computed(() => splitToChunks(props.questions, 2))
</script>